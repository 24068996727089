// Imports
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import authenticate from '@/auth/authenticate';
import admin from '@/auth/admin';
import LogAPIService from '@/services/LogAPIService';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/language/:lang',
            component: () => import('@/views/app/Index'),
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('userInfo')?.length) {
                    next('/app/pages/Home');
                } else {
                    const languagePath = to.params?.lang ? `/${to.params?.lang}` : '';
                    next(`/app/sessions/sign-in${languagePath}`);
                }
            }
        },
        {
            path: '/',
            component: () => import('@/views/app/Index'),
            beforeEnter: authenticate,
            redirect: '/app/pages/Home',

            children: [
                {
                    path: '/app/pages',
                    component: () => import('@/views/app/pages/Pages'),
                    children: [
                        {
                            path: 'home',
                            component: () => import('@/views/app/pages/Home')
                        },
                        {
                            path: 'post/:url',
                            component: () => import('@/views/app/pages/PostView')
                        },
                        {
                            path: 'evaluate/users/:user/:game',
                            name: 'evaluate-user',
                            component: () => import('@/views/app/pages/evaluation/EvaluateUser')
                        },
                        {
                            path: 'evaluate/users',
                            name: 'users-to-evaluate',
                            component: () => import('@/views/app/pages/evaluation/UsersToEvaluate')
                        },
                        {
                            path: 'evaluation/results',
                            name: 'evaluation-results',
                            component: () => import('@/views/app/pages/evaluation/Results')
                        },
                        {
                            path: 'personal-development-plan',
                            name: 'personal-development-plan',
                            component: () => import('@/views/app/pages/evaluation/PersonalDevelopmentPlan')
                        },
                        {
                            path: 'game/:url',
                            name: 'GamePostView',
                            component: () => import('@/views/app/pages/GamePostView')
                        },
                        {
                            path: 'profile',
                            component: () => import('@/views/app/pages/Profile')
                        },
                        {
                            path: 'termsAccepted',
                            component: () => import('@/views/app/pages/TermsAccepted')
                        } /* ,
                        {
                            path: 'legalBasesAccepted',
                            component: () => import('@/views/app/pages/LegalBasesAccepted')
                        },
                        {
                            path: 'gifts',
                            component: () => import('@/views/app/pages/MarketPlace')
                        },
                        {
                            path: 'statistics',
                            component: () => import('@/views/app/pages/StatisticsDetails')
                        },
                        {
                            path: 'ranking',
                            component: () => import('@/views/app/pages/Ranking')
                        },
                        {
                            path: 'quiz/:id',
                            component: () => import('@/views/app/pages/GameView')
                        },
                        {
                            path: 'quiz',
                            component: () => import('@/views/app/pages/Quiz')
                        } */
                    ]
                }
            ]
        },
        {
            path: '/app/admin',
            component: () => import('@/views/app/IndexAdmin'),
            beforeEnter: admin,

            children: [
                {
                    path: '/',
                    component: () => import('@/views/app/pages/Pages'),
                    redirect: '/app/admin/dashboard',
                    children: [
                        {
                            path: 'dashboard',
                            component: () => import('@/views/app/pages/admin/DashBoard')
                        },
                        {
                            path: 'users',
                            component: () => import('@/views/app/pages/admin/Users')
                        },
                        {
                            path: 'roles',
                            component: () => import('@/views/app/pages/admin/Roles')
                        },
                        {
                            path: 'companies/create/:id',
                            name: 'CompaniesCreate',
                            component: () => import('@/views/app/pages/admin/CompaniesCreate')
                        },
                        {
                            path: 'companies',
                            component: () => import('@/views/app/pages/admin/Companies')
                        },
                        {
                            path: 'posts/create/:id',
                            name: 'PostsCreate',
                            component: () => import('@/views/app/pages/admin/PostsCreate')
                        },
                        {
                            path: 'posts',
                            component: () => import('@/views/app/pages/admin/Posts')
                        },
                        {
                            path: 'articles/create',
                            name: 'ArticleCreate',
                            component: () => import('@/views/app/pages/admin/ArticlesCreate')
                        },
                        {
                            path: 'articles',
                            component: () => import('@/views/app/pages/admin/Articles')
                        },
                        {
                            path: 'terms/create',
                            name: 'TermsCreate',
                            component: () => import('@/views/app/pages/admin/TermsCreate')
                        },
                        {
                            path: 'terms',
                            component: () => import('@/views/app/pages/admin/Terms')
                        },
                        {
                            path: 'configs',
                            component: () => import('@/views/app/pages/admin/Configs')
                        },
                        {
                            path: 'classifiers/card_types',
                            component: () => import('@/views/app/pages/admin/games/classifiers/CardTypes')
                        },
                        {
                            path: 'classifiers/game_types',
                            component: () => import('@/views/app/pages/admin/games/classifiers/GameTypes')
                        },
                        {
                            path: 'games/create/:id',
                            name: 'GameCreate',
                            component: () => import('@/views/app/pages/admin/games/GameCreate')
                        },
                        {
                            path: 'games/info/:id',
                            name: 'GameInfo',
                            component: () => import('@/views/app/pages/admin/games/GameInfo')
                        },
                        {
                            path: 'games',
                            component: () => import('@/views/app/pages/admin/games/Games')
                        },
                        {
                            path: 'periods',
                            component: () => import('@/views/app/pages/admin/games/evaluations/Periods')
                        },
                        {
                            path: 'usersEvaluations',
                            component: () => import('@/views/app/pages/admin/games/evaluations/UsersEvaluations')
                        },
                        {
                            path: 'cards/create',
                            name: 'CardCreate',
                            component: () => import('@/views/app/pages/admin/games/CardCreate')
                        },
                        {
                            path: 'cards',
                            name: 'Cards',
                            component: () => import('@/views/app/pages/admin/games/Cards')
                        },
                        {
                            path: 'classifiers/behaviors',
                            component: () => import('@/views/app/pages/admin/games/evaluations/Behaviors')
                        },
                        {
                            path: 'classifiers/behaviors/create/:id?',
                            name: 'BehaviorCreate',
                            component: () => import('@/views/app/pages/admin/games/evaluations/BehaviorCreate')
                        },
                        {
                            path: 'classifiers/attributes',
                            component: () => import('@/views/app/pages/admin/games/evaluations/Attributes')
                        },
                        {
                            path: 'classifiers/attributes/create/:id?',
                            name: 'AttributeCreate',
                            component: () => import('@/views/app/pages/admin/games/evaluations/AttributeCreate')
                        },
                        {
                            path: 'classifiers/dimensions',
                            component: () => import('@/views/app/pages/admin/games/evaluations/Dimensions')
                        },
                        {
                            path: 'classifiers/dimensions/create/:id?',
                            name: 'DimensionCreate',
                            component: () => import('@/views/app/pages/admin/games/evaluations/DimensionCreate')
                        }
                    ]
                }
            ]
        },
        {
            path: '/app/sessions',
            component: () => import('@/views/app/sessions/Sessions'),

            children: [
                {
                    path: 'error',
                    component: () => import('@/views/app/sessions/Error')
                },
                {
                    path: 'forgot',
                    component: () => import('@/views/app/sessions/Forgot')
                },
                {
                    path: 'pass-reset',
                    component: () => import('@/views/app/sessions/NewPassword')
                },
                {
                    path: 'sign-in/:lang?',
                    name: 'login',
                    component: () => import('@/views/app/sessions/SignInUsernameCode')
                },
                {
                    path: 'sign-up-2',
                    name: 'signup',
                    component: () => import('@/views/app/sessions/SignUpTwo')
                },
                {
                    path: 'sign-in-token',
                    component: () => import('@/views/app/sessions/SignInToken')
                }
            ]
        },
        {
            // will match everything (use for not found route error:404)
            path: '*',
            redirect: '/app/pages/Home'
        }
    ]
});

router.beforeEach((to, from, next) => {
    // If this isn't an initial page load.
    if (to.path) {
        // Start the route progress bar.
        store.dispatch('changeThemeLoadingState', true);
    }
    next();
});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500);

    // Registering Log Access View
    if (!to.path.startsWith('/app/sessions'))
        setTimeout(
            path => {
                LogAPIService.registerAccessView({ view: path });
            },
            2000,
            to.path
        );
});

export default router;
