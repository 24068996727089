import BaseApiServices from '@/services/BaseApiServices';

class EvaluationsAPIService extends BaseApiServices {
    async getMyCurrentState({ gameId, evaluatedUserId }) {
        const url = `/games/evaluations/getMyCurrentState/${gameId}/${evaluatedUserId}`;

        return await this.getAxiosInstance().get(url);
    }

    async getCompanyRankings({ signal = null, defaultGameRanking = null }) {
        let url = `games/statistic/companyRankings`;

        if (defaultGameRanking) {
            url += '?defaultGameRanking=' + defaultGameRanking;
        }

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getRankingUserDetails({ companyId, gameId, signal = null }) {
        const url = `admin/games/statistic/ranking/${companyId}/${gameId}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getUsersToEvaluate(signal = null) {
        const url = `games/evaluations/getUsersToEvaluate`;
        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async getEvaluatingInfo(signal = null) {
        const url = `games/evaluations/getEvaluatingInfo`;
        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async finishGame({ gameId, evaluatedUserId }) {
        const url = `/games/evaluations/finishGame/${gameId}/${evaluatedUserId}`;

        return await this.getAxiosInstance().post(url);
    }

    async generateStatsFromCurrentPeriod({ companyId, gameId }) {
        const url = `/games/evaluations/generateStatsFromCurrentPeriod/${companyId}/${gameId}`;

        return await this.getAxiosInstance().post(url);
    }

    async getResults({ companyId, gameId, userId }) {
        const url = `/games/evaluations/results/${companyId}/${gameId}/${userId}`;

        return await this.getAxiosInstance().post(url);
    }

    async getClassifiers({ companyId }) {
        const url = `/games/evaluations/classifiers/${companyId}`;

        return await this.getAxiosInstance().get(url);
    }

    /*
     ************ Cards ***********
     */

    async getChoicesFromCard({ cardId, signal = null }) {
        const url = `games/evaluations/cards/${cardId}/choices`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async answerQuestion({ gameId, cardId, evaluatedUserId, answer }) {
        const url = `/games/evaluations/answer/${gameId}/${cardId}/${evaluatedUserId}`;

        return await this.getAxiosInstance().post(url, {
            answer
        });
    }

    /**
     * Classifiers (Behaviors - Attributes - Dimensions)
     */

    async getBehaviorForAdmin({ id, signal = null }) {
        const url = `admin/games/evaluations/behaviors/${id}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async updateBehaviorForAdmin({ urlAppend, formData }) {
        const urlMain = 'admin/games/evaluations/behaviors';
        const data = await this.getAxiosInstance().post(`${urlMain}${urlAppend}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        return data;
    }

    async getAttributeForAdmin({ id, signal = null }) {
        const url = `admin/games/evaluations/attributes/${id}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async updateAttributeForAdmin({ urlAppend, formData }) {
        const urlMain = 'admin/games/evaluations/attributes';
        const data = await this.getAxiosInstance().post(`${urlMain}${urlAppend}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        return data;
    }

    async getDimensionForAdmin({ id, signal = null }) {
        const url = `admin/games/evaluations/dimensions/${id}`;

        const data = await this.getAxiosInstance().get(url, { signal });

        return data;
    }

    async updateDimensionForAdmin({ urlAppend, formData }) {
        const urlMain = 'admin/games/evaluations/dimensions';
        const data = await this.getAxiosInstance().post(`${urlMain}${urlAppend}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        return data;
    }

    async getPeriodsDataUser({ id, signal = null }) {
        const url = `games/evaluations/periodsDataUser/${id}`;

        return await this.getAxiosInstance().get(url, { signal });
    }

    async updatePeriodsDataUser({ urlAppend, formData }) {
        const urlMain = 'games/evaluations/periodsDataUser';

        return await this.updateOrCreateItem({ urlMain, urlAppend, formData });
    }

    async initializePeriod() {
        const url = 'admin/games/evaluations/initializePeriod';

        return await this.getAxiosInstance().post(url);
    }
}

export default new EvaluationsAPIService();
